import Domain from "../domain";
export default {
  methods: {
    getGalleryList: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/product/gallery/details",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putGalleryImages: async function(obj) {
        try {
            let response = await this.axios.put(
            Domain.APP_DOMAIN + "merchant/product/upload/image", obj,
            Domain.APP_MERCHANT_HEADER_FORM_DATA
            );
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    putBulkGalleryImages: async function(arr) {
        try {
            let response = await this.axios.put(
            Domain.APP_DOMAIN + "merchant/product/upload/bulk/image", arr,
            Domain.APP_MERCHANT_HEADER_FORM_DATA,
            );
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    deleteGalleryImg: async function(id) {
        try {
          let response = await this.axios.delete(
            Domain.APP_DOMAIN + "merchant/product/upload/image/" + id,
            Domain.APP_MERCHANT_HEADER
          );
          if (!response || !response.data) {
            throw Error("resource not found.");
          }
          return response.data;
        } catch (error) {
          throw error;
        }
    }
  }
}