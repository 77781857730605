<template>
    <div>
        <div class="container galleryContainer">

            <div class="row mt-2 mb-5">
                <h1 class="font-weight-light text-center text-lg-left mt-4 mb-0">GALLERY</h1>
                <span style="color: red">* Image Size must be 1000x1000 pixels. <br/>* Keep a 100px space on either sides and ensure the product is centered.</span><br/>
                <span style="color: red">* Only 10 images at a time can be uploaded by bulk.</span><br/>
                <span style="color: red">* Maximum image size 3MB</span>
                <button class="btn btn-sm" @click="pickKycProf2()" style="float: right; margin-right: 20px;">Add New</button> 
                <button class="btn btn-sm" @click="addNewBulk()" style="float: right; margin-right: 20px;">Add New Bulk</button> 
                <input type="file" @change="getFile1" class="inputfile" id="upprofpicturemer2" ref="upprofpicturemer2" style="display: none;">
                <input type="file" @change="getFile2" class="inputfile" id="upprofpicturemer3" ref="upprofpicturemer3" style="display: none;" multiple>
            </div>

            <hr class="mt-2 mb-5">

            <div class="row text-center text-lg-left">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6" style="margin-bottom: 40px;" v-for="item in images" :key="item._id">
                    <a class="d-block mb-4 h-100">
                        <i class="fa fa-times del" @click="haddleDelete(item._id)"></i>
                        <div class="imgContainer">
                        <img class="img-fluid img-thumbnail galaryImg" :src='item.image' alt="">
                        </div>
                        <i class="fa fa-copy" @click="copyUrl(item.image)" style="position: absolute; bottom: 0px; right: 15px; width: 30px; height: 30px; text-align: center; line-height: 30px; background-color: green; color: white;cursor: pointer;"></i>
                        <!-- <i class="fa fa-copy copy"></i> -->
                    </a>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import mixinGalary from "@/mixins/merchant/APIGalary";
export default {
    mixins: [mixinGalary],
    data: function() {
        return {
            images: [],
            image1: "",
            img1: "",
            bulckFiles: '',
            fileUploadFormData: new FormData(),
            BulkfileUploadFormData: new FormData(),
            imgObj: {},
            breadcrumb: [
                {
                    text: "Gallery",
                    href: "/gallery-list",
                    active: true
                }
            ]
        }
    },
    created() {
        this.handleBreadcrumb(); 
        this.haddleGet();
    },
    methods: {
      handleBreadcrumb: function() {
        this.$store.commit("setBreadcrumbSwitchState", {
          action: null,
          payload: this.breadcrumb
        });
      },
      copyUrl: function(url) {
          this.$copyText(url);
            this.$swal.fire({
                position: "top-end",
                type: "success",
                title: "Copied!",
                showConfirmButton: false,
                timer: 800,
            });
      },
      haddleGet: async function() {
        try {
            let response = await this.getGalleryList();
            this.images = response;
        } catch (error) {
            this.$swal.fire({
            position: "center",
            type: "error",
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500,
            });
        }
      },
      pickKycProf2: function() {
        this.$refs.upprofpicturemer2.click();
      },
      addNewBulk: function() {
        this.$refs.upprofpicturemer3.click();
      },
      getFile1: async function(eventf) {
        var tgt = eventf.target || window.event.srcElement;
        let files = tgt.files;

        let self = this;
        if (FileReader && files && files.length) {
            var fr = new FileReader();
            fr.onload = function() {
            self.image1 = fr.result;
            };
            fr.readAsDataURL(files[0]);
        }
        this.image1 = tgt.files[0];
        this.img1 = eventf.target.files[0]
            console.log(this.img1)
        this.haddleSaveImages();
      },
      getFile2: async function() {
        
        let formData = new FormData();
        console.log(this.$refs.upprofpicturemer3.files)
        for( var i = 0; i < this.$refs.upprofpicturemer3.files.length; i++ ){
            let file = this.$refs.upprofpicturemer3.files[i];
            formData.append('image', file);
        }

        this.submitBulkImgs(formData);
      },
      submitBulkImgs: async function(bulckFiles) {
        try {
            await this.putBulkGalleryImages(bulckFiles);
            this.$swal.fire({
                position: 'center',
                type: 'success',
                title: 'Submit images success',
                showConfirmButton: false,
                timer: 1500
            });
            this.haddleGet();
        } catch (error) {
            this.$swal.fire({
            position: "center",
            type: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 1500
            });
        }
      },
      haddleSaveImages: async function() {
        await this.fileUploadFormData.set("image", this.img1);
        let formData = await this.fileUploadFormData;
        try {
            await this.putGalleryImages(formData);
            this.$swal.fire({
                position: 'center',
                type: 'success',
                title: 'Submit images success',
                showConfirmButton: false,
                timer: 1500
            });
            this.haddleGet();
        } catch (error) {
            this.$swal.fire({
            position: "center",
            type: "error",
            title: error.message,
            showConfirmButton: false,
            timer: 1500
            });
        }
      },
      haddleDelete: async function(id) {
        try {
            let self = this;
            this.$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            }).then((result) => {
            if (result.value) {
                self.deleteImage(id);
                this.$swal(
                "Deleted!",
                "Image has been deleted successfully",
                "success"
                );
            }
            });
        } catch (error) {
            this.$toast.error("Delete!", "Error", {
            position: "topRight",
            });
        }
      },
      deleteImage: async function(id) {
        try {
            await this.deleteGalleryImg(id);
            this.haddleGet();
        } catch (error) {
            console.log(error);
        }
      }
    }
}
</script>
<style scoped>
.galleryContainer {
    width: 100% !important;
}
.del {
    position: absolute;
    top: 0px;
    right: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: red;
    color: white;
    cursor: pointer
}
.copy {
    position: absolute;
    right: 15px;
    width: 30px;
    bottom: 0px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: #5779ae;
    color: white;
    cursor: pointer;
}

.imgContainer {
    width: 100%; 
    height: 250px;
}

.galaryImg {
  width: 100%; 
  height: 100%;
}

.galaryImg:hover {
  width: 100%; 
  height: 100%;
  border: 5px solid #666666;
}
@media screen and (max-width:1280px)  {
    .img-thumbnail {
        max-width: 100% !important;
    }
}

@media screen and (max-width:475px) {
    .col-xs-6 {
        width: 100% !important;
    }

    .imgContainer {
        width: 100% !important; 
        height: auto !important;
    }
}
</style>